import styled from "styled-components"

import { isThisMonth, isThisWeek, isToday, isYesterday } from "date-fns"

import {
  TRecentlyViewedPage,
  TRecentlyViewedPageType,
} from "src/components/RecentlyViewed/RecentlyViewedContext"
import { useRecentlyViewedContext } from "src/components/RecentlyViewed/useRecentlyViewedContext"
import { MButton } from "src/ui/Button/MButton"
import { mColors } from "src/ui/colors"
import CartIcon from "src/ui/icons/cart.svg"
import HomeIcon from "src/ui/icons/home.svg"
import OrganizationIcon from "src/ui/icons/organization.svg"
import UserIcon from "src/ui/icons/profile.svg"
import DeviceIcon from "src/ui/icons/sensor.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MPopover } from "src/ui/MPopover/MPopover"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const PAGE_TYPE_ICON_MAP: Record<TRecentlyViewedPageType, React.ReactNode> = {
  Device: <DeviceIcon />,
  Home: <HomeIcon />,
  User: <UserIcon />,
  Organization: <OrganizationIcon />,
  Order: <CartIcon />,
}

export function RecentlyViewedPopover({
  trigger,
}: {
  trigger: React.ReactNode
}) {
  const { recentlyViewedPages, clear } = useRecentlyViewedContext()

  const groupedPages =
    groupRecentlyViewedPagesByRelativeDate(recentlyViewedPages)

  return (
    <MPopover
      trigger={trigger}
      triggerType="hover"
      placement="right-start"
      offset={({ rects }) => {
        return {
          mainAxis: -30,
          // Push the floating element up by 1/4 of its height
          alignmentAxis: (rects.floating.height / 4) * -1,
        }
      }}
      disableScroll
    >
      <MCard>
        <TopWrapper>
          <MText variant="subtitle">Recently viewed</MText>
          <MButton variant="minimal" onClick={clear} tabIndex={-1}>
            Clear
          </MButton>
        </TopWrapper>
        <ContentWrapper>
          {recentlyViewedPages.length === 0 && (
            <EmptyLabel>No recently viewed pages</EmptyLabel>
          )}

          <ListWrapper>
            {groupedPages.map((group) => (
              <div key={group.label}>
                <StyledGroupLabel
                  variant="subtitleS"
                  color="secondary"
                  marginBottom={spacing.XS2}
                >
                  {group.label}
                </StyledGroupLabel>
                <ListWrapper>
                  {group.pages.map((page) => (
                    <StyledLink key={page.path} to={page.path}>
                      <IconWrapper>{PAGE_TYPE_ICON_MAP[page.type]}</IconWrapper>
                      <div>
                        <MText variant="subtitleS" textDecoration="underline">
                          {page.title}
                        </MText>
                        <MText variant="bodyS" color="secondary">
                          {page.enitytId} • {page.type}
                        </MText>
                      </div>
                    </StyledLink>
                  ))}
                </ListWrapper>
              </div>
            ))}
          </ListWrapper>
        </ContentWrapper>
      </MCard>
    </MPopover>
  )
}

function groupRecentlyViewedPagesByRelativeDate(
  recentlyViewedPages: TRecentlyViewedPage[]
) {
  const groupedMap = recentlyViewedPages.reduce<
    Map<string, { label: string; pages: TRecentlyViewedPage[] }>
  >((map, curr) => {
    const relativeDate = getRelativeDateLabel(new Date(curr.timestamp))

    const group = map.get(relativeDate)

    group
      ? group.pages.push(curr)
      : map.set(relativeDate, { label: relativeDate, pages: [curr] })

    return map
  }, new Map())

  return Array.from(groupedMap.values())
}

function getRelativeDateLabel(date: Date): string {
  if (isToday(date)) {
    return "Today"
  } else if (isYesterday(date)) {
    return "Yesterday"
  } else if (isThisWeek(date)) {
    return "In the last week"
  } else if (isThisMonth(date)) {
    return "In the last month"
  } else {
    return "More than a month ago"
  }
}

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.S};
  border-bottom: 1px solid ${mColors.divider};
`

const ContentWrapper = styled.div`
  max-height: 500px;
  overflow-y: auto;
`

const ListWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${spacing.S};
  }
`

const StyledGroupLabel = styled(MText)`
  padding: ${spacing.S} ${spacing.S} 0 ${spacing.S};
`

const StyledLink = styled(InternalLink)`
  display: flex;
  gap: ${spacing.S};
  align-items: center;
  padding: ${spacing.S};
  text-decoration: none;

  &:hover {
    background-color: ${mColors.neutral};
    text-decoration: none;
  }
`

const IconWrapper = styled.div`
  > svg {
    width: 25px;
  }
`

const EmptyLabel = styled(MText)`
  padding: ${spacing.S};
`
